import React from "react"
import { Link, graphql } from "gatsby"

import Header from "../components/Layout/header"
import Footer from "../components/Layout/footer"
import SEO from "../components/Global/MetaHead"

import Button from "react-bootstrap/Button"

const PageNotFound = ({ data }) => (
  <>
    <SEO
      title="Сторінка 404 | Центр Ліцензій"
      description="Центр Ліцензій. Сторінка, яку ви намагалися відкрити не знайдено. Код помилки - 404."
      image={`https:${data.ogImage.fixed.src}`}
      url="/404/"
      lang="uk"
    />
    <Header />
    <section className="404 container py-5">
      <h1>Сторінка 404</h1>
      <p>
        Сторінка, яку ви намагалися відкрити не знайдено. Код помилки - 404.
      </p>

      <div className="pt-3 pb-2 h2">Що можна зробити далі?</div>
      <ol>
        <li>Скористайтеся меню, щоб знайти потрібну сторінку</li>
        <li>Отримайте безкоштовну консультацію за телефоном. Дзвоніть!</li>
        <li>
          Перейдіть на{" "}
          <Link to="/" replace>
            головну сторінку
          </Link>{" "}
          і продовжіть роботу з сайтом.
        </li>
      </ol>
      <p className="text-center">
        <Button
          as={Link}
          variant="primary"
          className="dyakuemo-btn"
          id="page404"
          to="/"
          replace
        >
          На головну сторінку
        </Button>
      </p>
    </section>
    <Footer slug="404" />
  </>
)

export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default PageNotFound
